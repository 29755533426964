.wrap-holding-page {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-form {
    color: #000;
    font-size: 14px;
    width: 666px;
    padding: 36px 40px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-container {
      display: flex;
      justify-content: center;
      width: 90%;
      padding-bottom: 75px;
      border-bottom: 1px solid rgba(36, 36, 34, 0.15);

      .logo {
        width: 291px;
        height: 262px;
      }
    }
    
  }
  .modal-form__body {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label-form {
      color: #fff;
    width: 534px;

    .link-btn {
      color: #242422;
      text-align: center;
      font-family: Suisse Intl;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 214.286% */
      letter-spacing: 1.4px;
      text-transform: uppercase;
      height: 20px;
      cursor: pointer;
    }

    .link-btn {
      color: #fff;
      text-align: center;
      font-family: Suisse Intl;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 214.286% */
      letter-spacing: 1.4px;
      text-transform: uppercase;
      height: 20px;
      text-align: right;
      cursor: pointer;
    }
  }
  .modal-form__body .form-input {
    padding: 13px 20px;
    background: #F4F4F4;
    width: 100%;
    color: #242422;
    font-family: "Suisse Intl";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.2px;
    outline: none;
    border: none;
    height: 64px;

    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      color: #242422;
      text-transform: uppercase;
      font-family: "Suisse Intl";
    }
  }

  .modal-form__body .btn-link {
    text-decoration: underline;
    font-family: "Futura PT";
    font-style: medium;
    font-weight: 450;
    font-size: 14px;
    line-height: 100%;
    text-decoration-line: underline;
    color: #000000;
  }

  .modal-form__body .btn-forgot {
    font-family: "Futura PT" !important;
    font-style: medium;
    font-weight: 450;
    font-size: 14px;
    line-height: 100%;
    color: #7b7b7b !important;
  }

  .modal-form__body .btn-holding-login {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background: black;
    width: 100%;
    height: 60px;
    margin-top: 5px;
    span {
      color: #fff;
      text-align: center;
      font-family: "Suisse Intl";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2.25px;
      text-transform: uppercase;
    }
  }

  .spacing {
    margin-bottom: 25px;
  }
}
}

